import styled from '@emotion/styled';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Alert as MuiAlert,
  Button as MuiButton,
  Paper as MuiPaper,
  TextField,
} from '@mui/material';
import { spacing } from '@mui/system';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../hooks/useToast';
import { useAuthContext } from '../../contexts/AuthContext';
import ErrorBanner from '../errorhandling/ErrorBanner';
import { errorSeverity } from '../../types/errorhandling.types';
import { useDeleteBranding } from '../../hooks/api/useBranding';

const Button = styled(MuiButton)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Alert = styled(MuiAlert)`
  position: fixed;
  padding: 20px;
  right: 0;
  top: 70px;
  z-index: 1;
`;

const StyledTextField = styled(TextField)`
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  &:focus {
    outline: 2px solid #177d8e;
  }
`;

export interface DeleteAccountDialogProps {
  text: string;
}

export function DeleteAccountDialog({ text }: DeleteAccountDialogProps) {
  const { fetchAccountList, accountList, account } = useAuthContext();
  const [accountName, setAccountName] = useState('');
  useEffect(() => {
    fetchAccountList();
  }, [fetchAccountList]);

  useEffect(() => {
    const selectedName = Object.keys(accountList).find(
      (key) => accountList[key] === account
    );
    setAccountName(selectedName || '');
  }, [account, accountList]);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const { deleteBranding } = useDeleteBranding();
  const { show: showSuccess, trigger: triggerSuccess } = useToast();
  const { show: showError, trigger: triggerError } = useToast();
  const [error, setError] = useState<string | null>(null);

  const { t } = useTranslation();
  const handleSubmit = async () => {
    setError(null);

    if (name !== accountName) {
      setError(t('navbar.removeAccountDialog.nameMissmatchError'));
      return;
    }
    try {
      await deleteBranding(account);
      setName('');
      setOpen(false);
      triggerSuccess();
      setTimeout(() => window.location.reload(), 2000);
    } catch {
      triggerError();
    }
  };

  return (
    <>
      <StyledButton
        variant="text"
        color="error"
        onClick={() => setOpen(true)}
        sx={{ marginRight: '14px' }}
      >
        <DeleteForeverIcon sx={{ fontSize: 16, marginRight: 4 }} />
        {text}
      </StyledButton>
      <Paper mt={4}>
        <div>
          <Dialog
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              {t('navbar.removeAccountDialog.title')}
            </DialogTitle>
            <DialogContent>
              <Grid mb={6}>
                <DialogContentText>
                  {t('navbar.removeAccountDialog.subtext')}
                </DialogContentText>
                <DialogContentText
                  style={{
                    marginTop: '12px',
                    fontWeight: '600',
                    fontSize: '14px',
                  }}
                >
                  "{accountName}"
                </DialogContentText>
              </Grid>
              <StyledTextField
                required
                margin="dense"
                id="name"
                label={t('navbar.removeAccountDialog.name')}
                type="text"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={!!error}
              />
              {error && (
                <ErrorBanner
                  styles={{ marginTop: '8px' }}
                  errorMessage={error}
                  severity={errorSeverity.Error}
                  layer={0}
                />
              )}
            </DialogContent>
            <DialogActions>
              <StyledButton
                onClick={() => {
                  setOpen(false);
                  setError(null);
                }}
                color="primary"
              >
                {t('navbar.removeAccountDialog.cancel')}
              </StyledButton>
              <StyledButton onClick={handleSubmit} color="primary">
                {t('navbar.removeAccountDialog.remove')}
              </StyledButton>
            </DialogActions>
          </Dialog>
        </div>
        {showSuccess && (
          <Alert severity="success">
            {t('navbar.removeAccountDialog.success')}
          </Alert>
        )}
        {showError && (
          <Alert severity="error">
            {t('navbar.removeAccountDialog.error')}
          </Alert>
        )}
      </Paper>
    </>
  );
}
