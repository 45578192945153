import { TextField } from '@mui/material';

type IconDescriptionInputProps = {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const IconDescriptionInput = ({
  name,
  value,
  onChange,
}: IconDescriptionInputProps) => {
  return (
    <TextField
      name={name}
      label="Icon description"
      type="text"
      onChange={onChange}
      value={value}
      fullWidth
      sx={{
        maxWidth: '600px',
        width: { xs: '90%' },
        marginTop: { xs: '20px' },
      }}
      error={value.length >= 150}
      helperText={
        value.length >= 150
          ? 'Description should not exceed 150 characters.'
          : ''
      }
      InputProps={{
        style: {
          borderRadius: 0,
        },
      }}
      InputLabelProps={{
        style: {
          color: '#575959',
        },
      }}
    />
  );
};

export default IconDescriptionInput;
