import styled from '@emotion/styled';
import { useState } from 'react';

import {
  Alert as MuiAlert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper as MuiPaper,
  TextField,
} from '@mui/material';
import { spacing } from '@mui/system';
import { useUsers } from '../../../hooks/api/useUsers';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const Paper = styled(MuiPaper)(spacing);

const Alert = styled(MuiAlert)`
  position: fixed;
  padding: 20px;
  right: 0;
  top: 70px;
  z-index: 1;
`;

const StyledTextField = styled(TextField)`
  margin-top: 20px;
`;

const StyledAddButton = styled(Button)`
  &:focus {
    outline: 2px solid #0b313f;
  }
`;

const StyledButton = styled(Button)`
  &:focus {
    outline: 2px solid #177d8e;
  }
`;

export type SimpleDialogProps = {
  text: string;
  onClick: () => void;
};

const AddUserDialog = ({ text, onClick }: SimpleDialogProps) => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [apiError, setApiError] = useState<string>('');
  const [showToast, setShowToast] = useState(false);

  const { addUser } = useUsers();

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    try {
      setEmailError(false);
      setApiError('');

      if (!email || !validateEmail(email)) {
        setEmailError(true);
      }

      if (email && validateEmail(email)) {
        await addUser({ data: { email } });
        setOpen(false);
        setEmail('');
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 1600);
        onClick();
      }
    } catch (error: any) {
      if (
        !!error.response.data &&
        error.response.data.errors[0].message === 'already exists'
      ) {
        setApiError('A user with that email already exists.');
      } else {
        setApiError('Something went wrong when creating a new user.');
      }
    }
  };

  return (
    <Paper mt={4}>
      <div>
        <StyledAddButton
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          {text} <PersonAddIcon sx={{ marginLeft: '8px' }} />
        </StyledAddButton>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add Admin</DialogTitle>
          <DialogContent>
            <Grid mb={6}>
              <DialogContentText>
                Add a new admin by entering their email address and click Add
                Admin
              </DialogContentText>
            </Grid>
            <StyledTextField
              required
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
              error={emailError || !!apiError}
              InputLabelProps={{
                style: {
                  color: '#575959',
                },
              }}
            />
            {emailError && <div>Please enter a valid email address.</div>}
            {apiError && <div>{apiError}</div>}
          </DialogContent>
          <DialogActions>
            <StyledButton
              onClick={() => {
                setOpen(false);
                setEmail('');
                setEmailError(false);
                setApiError('');
              }}
              color="primary"
            >
              Cancel
            </StyledButton>
            <StyledButton onClick={handleSubmit} color="primary">
              Add Admin
            </StyledButton>
          </DialogActions>
        </Dialog>
      </div>
      {showToast && (
        <Alert severity="success">User has been added successfully!</Alert>
      )}
    </Paper>
  );
};

export default AddUserDialog;
