import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { useAgreements } from '../../../../hooks/api/useAgreements';
import { useNavigate } from 'react-router-dom';

export const DeleteAgreementModal = ({
  id,
  journalMode,
  journalId,
}: {
  id: string;
  journalMode: boolean;
  journalId: string | undefined;
}) => {
  const { deleteAgreementById, deleteJournal } = useAgreements();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    if (journalMode) {
      await deleteJournal(id, journalId!);
      handleClose();
      navigate(0);
    } else {
      await deleteAgreementById(id);
      handleClose();
      navigate('/agreements');
    }
  };

  return (
    <>
      <Button
        sx={{ marginRight: '14px', marginBottom: 2 }}
        color="error"
        variant="contained"
        onClick={handleOpen}
      >
        {journalMode
          ? t('agreements.deleteJournal')
          : t('agreements.deleteAgreement')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('agreements.areYouSureTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {journalMode
              ? t('agreements.areYouSureMsgJournal')
              : t('agreements.areYouSureMsg')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            {t('agreements.cancelDelete')}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleConfirm}
            autoFocus
          >
            {journalMode
              ? t('agreements.confirmDeleteJournal')
              : t('agreements.confirmDelete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
