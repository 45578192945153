import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { Journals } from '../../types/Journals.types';

const baseUrl = process.env.REACT_APP_API_URL || 'https://api.jstscifree.com';

export const useSearch = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { account } = useAuthContext();

  const makeApiRequest = useCallback(
    async (
      url: string,
      method: AxiosRequestConfig['method'],
      data: any = null
    ) => {
      const accessToken = await getAccessTokenSilently();

      const config: AxiosRequestConfig = {
        method,
        url: `${baseUrl}${url}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'X-SciFree-Context': account,
        },
        data,
      };

      const response: AxiosResponse = await axios(config);
      return response.data;
    },
    [getAccessTokenSilently, account]
  );

  const searchJournals = useCallback(
    async (
      searchQuery: string,
      licenses: string[],
      publishingModels: string[],
      payments: string[],
      pubhishers: string[]
    ) => {
      let params = `/search?term=${searchQuery}&account=${account}`;

      if (searchQuery === '') {
        return [];
      }

      if (licenses.length > 0) {
        params += `&licenses[]=${encodeURI(licenses.join('&licenses[]='))}`;
      }
      if (publishingModels.length > 0) {
        params += `&publishing_model[]=${encodeURI(
          publishingModels.join('&publishing_model[]=')
        )}`;
      }
      if (payments.length > 0) {
        params += `&payment[]=${encodeURI(payments.join('&payment[]='))}`;
      }
      if (pubhishers.length > 0) {
        params += `&imprint[]=${encodeURI(pubhishers.join('&imprint[]='))}`;
      }

      return (await makeApiRequest)(params, 'GET');
    },
    [makeApiRequest, account]
  );

  const getBaseFilters = useCallback(
    async (searchQuery: string) => {
      let Journals = await searchJournals(searchQuery, [], [], [], []);
      let licenses: Set<string> = new Set();
      let publishingModels: Set<string> = new Set();
      let payments: Set<string> = new Set();
      let imprints: Set<string> = new Set();

      Journals.forEach((journal: Journals) => {
        if (journal.licenses) {
          journal.licenses.forEach((license: string) => {
            licenses.add(license);
          });
        }
        if (journal.publishing_model) {
          publishingModels.add(journal.publishing_model);
        }
        if (journal.payment) {
          payments.add(journal.payment);
        }
        if (journal.imprint) {
          imprints.add(journal.imprint);
        }
      });

      let sortSet = (set: Set<string>) => {
        return Array.from(set).sort((a, b) => {
          return a.localeCompare(b);
        });
      };

      return {
        licenses: sortSet(licenses),
        publishingModels: sortSet(publishingModels),
        payments: sortSet(payments),
        imprints: sortSet(imprints),
      };
    },
    [searchJournals]
  );

  return {
    searchJournals,
    getBaseFilters,
  };
};
