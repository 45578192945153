import { useState } from 'react';
import { MenuItem, Select, Box, Grid } from '@mui/material';
import { useUsers } from '../../hooks/api/useUsers';
import { useAuthContext } from '../../contexts/AuthContext';
import BusinessIcon from '@mui/icons-material/Business';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';

const NavbarAccountSelector = () => {
  const { fetchAccount } = useUsers();
  const navigate = useNavigate();
  const { account, setAccount } = useAuthContext();
  const { isAuthenticated } = useAuth0();
  const [accountList, setAccountList] = useState<Record<string, string>>({});

  if (Object.keys(accountList).length === 0) {
    fetchAccount().then((res) => setAccountList(res || {}));
  }

  const updateAccount = (newAccount: any) => {
    setAccount(newAccount);
    if (window.location.pathname.includes('/agreements/')) {
      navigate('/agreements');
    }
  };

  if (!isAuthenticated) {
    return <></>;
  }

  while (Object.keys(accountList).length === 0) {
    return <Loader />;
  }

  const sortedAccountNames = Object.keys(accountList).sort();

  return (
    <Box sx={{ display: 'flex', alignItem: 'center' }}>
      <Grid
        item
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginX: '8px',
        }}
      >
        <BusinessIcon style={{ fill: '#0B313F' }} />
      </Grid>
      <Select
        inputProps={{ id: 'account' }}
        id="account"
        value={account}
        onChange={(e) => updateAccount(e.target.value)}
      >
        {sortedAccountNames.map((name: any) => (
          <MenuItem key={accountList[name]} value={accountList[name]}>
            {name} - {accountList[name]}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default NavbarAccountSelector;
