import styled from '@emotion/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton as MuiIconButton,
} from '@mui/material';

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }

  &:focus {
    outline: 2px solid #177d8e;
  }
`;

const StyledButton = styled(Button)`
  &:focus {
    outline: 2px solid #177d8e;
  }
`;

type DeleteUserProps = {
  onClick: () => void;
};

export const DeleteUserDialog = ({ onClick }: DeleteUserProps) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    onClick();
    handleClose();
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="Open dialog"
        onClick={handleClickOpen}
        size="small"
      >
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this admin?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This admin will be permanently deleted and cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton
            onClick={handleClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </StyledButton>
          <StyledButton
            onClick={handleDelete}
            color="error"
            variant="contained"
            autoFocus
          >
            Delete
          </StyledButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteUserDialog;
