import React from 'react';
import { useStatistics } from '../../hooks/api/useStatistics';
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { Bar, Line } from 'react-chartjs-2';
import Colors from '../../theme/colors';
import { TabContext, TabPanel } from '@mui/lab';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useTranslation } from 'react-i18next';

import { ExpandMore, UnfoldMore } from '@mui/icons-material';
import { useAuthContext } from '../../contexts/AuthContext';
import { useUsers } from '../../hooks/api/useUsers';

const StyledButton = styled(Button)`
  && {
    font-weight: 500;

    font-size: 14px;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }
`;

const arrowStylingDefault = {
  transition: 'transform 150ms ease',
};

const arrowStyling = {
  transform: 'rotate(-180deg)',
  transition: 'transform 150ms ease',
};

const StyledTabBtn = styled(Button)`
  border-radius: 0;

  &:focus {
    background: rgba(0, 0, 0, 0.04);
  }

  &:hover {
  }
`;

const activeTabStyles = {
  borderBottom: '3px solid #177d8e',
  background: 'rgba(0, 0, 0, 0.04)',
};

function Statistics() {
  const [accountList, setAccountList] =
    React.useState<Record<string, string>>();
  const [top10, setTop10] = React.useState([]);
  const [noResults, setNoResults] = React.useState([]);
  const [startDate, setStartDate] = React.useState<Dayjs>(
    dayjs().subtract(1, 'month')
  );
  const [endDate, setEndDate] = React.useState<Dayjs>(dayjs());
  const [byDate, setByDate] = React.useState<any[]>([]);
  const [filtersByDate, setFiltersByDate] = React.useState<any[]>([]);
  const [eventsByDate, setEventsByDate] = React.useState<any[]>([]);
  const [totalSearch, setTotalSearch] = React.useState(0);
  const [totalJournal, setTotalJournal] = React.useState(0);
  const [totalPublishingModels, setTotalPublishingModels] = React.useState(0);
  const [totalPayments, setTotalPayments] = React.useState(0);
  const [totalLicenses, setTotalLicenses] = React.useState(0);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] =
    React.useState<keyof JournalsByDate>('agreement_id');
  const [journalsByDate, setJournalsByDate] = React.useState([]);
  const [totalFilterImprint, setTotalFilterImprint] = React.useState(0);
  const [totalFilterPublishingModel, setTotalFilterPublishingModel] =
    React.useState(0);
  const [totalFilterPayments, setTotalFilterPayments] = React.useState(0);
  const [totalFilterLicenses, setTotalFilterLicenses] = React.useState(0);
  const { loggedInAccount } = useAuthContext();

  const { t } = useTranslation();

  React.useEffect(() => {
    const totalSearch = byDate
      .filter((e) => e.event === 'search')
      .reduce((acc, item) => acc + item.count, 0);
    setTotalSearch(totalSearch);
    const totalJournal = byDate
      .filter((item) => item.event === 'Journal')
      .reduce((acc, item) => acc + item.count, 0);
    setTotalJournal(totalJournal);
    const totalPublishingModels = byDate
      .filter((item) => item.event === 'Publishing Models')
      .reduce((acc, item) => acc + item.count, 0);
    setTotalPublishingModels(totalPublishingModels);
    const totalPayments = byDate
      .filter((item) => item.event === 'Payments')
      .reduce((acc, item) => acc + item.count, 0);
    setTotalPayments(totalPayments);
    const totalLicenses = byDate
      .filter((item) => item.event === 'Licenses')
      .reduce((acc, item) => acc + item.count, 0);
    setTotalLicenses(totalLicenses);

    const totalFilterImprint = filtersByDate
      .filter((item) => item.filter === 'Imprint')
      .reduce((acc, item) => acc + item.count, 0);
    setTotalFilterImprint(totalFilterImprint);
    const totalFilterPublishingModels = filtersByDate
      .filter((item) => item.filter === 'Publishing Model')
      .reduce((acc, item) => acc + item.count, 0);
    setTotalFilterPublishingModel(totalFilterPublishingModels);
    const totalFilterPayment = filtersByDate
      .filter((item) => item.filter === 'Payment')
      .reduce((acc, item) => acc + item.count, 0);
    setTotalFilterPayments(totalFilterPayment);
    const totalFilterLicenses = filtersByDate
      .filter((item) => item.filter === 'Licenses')
      .reduce((acc, item) => acc + item.count, 0);
    setTotalFilterLicenses(totalFilterLicenses);
  }, [byDate, filtersByDate, loggedInAccount]);

  const {
    fetchStatisticsTop10,
    fetchStatisticsNoResults,
    fetchStatisticsByDate,
    fetchJournalsByDate,
    fetchFiltersByDate,
    fetchEventsByDate,
  } = useStatistics();

  const { fetchAccount } = useUsers();

  React.useEffect(() => {
    const getAccountList = async () => {
      const accounts = await fetchAccount();
      setAccountList(accounts);
    };
    getAccountList();
    const getStatistics = async () => {
      const top10 = await fetchStatisticsTop10();
      const journalsByDate = await fetchJournalsByDate(
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD')
      );
      const noResults = await fetchStatisticsNoResults();
      const byDate = await fetchStatisticsByDate(
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD')
      );
      const eventsByDate = await fetchEventsByDate(
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD')
      );
      setEventsByDate(eventsByDate.filter((e: any) => e.event === 'search'));
      setJournalsByDate(journalsByDate);
      setTop10(top10);
      setNoResults(noResults);
      byDate.forEach((item: any) => {
        if (item.event === 'journal') item.event = 'Journal';
        if (item.event === 'publishing_models')
          item.event = 'Publishing Models';
        if (item.event === 'payments') item.event = 'Payments';
        if (item.event === 'licenses') item.event = 'Licenses';
      });
      setByDate(byDate);
      const filtersByDate = await fetchFiltersByDate(
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD')
      );
      filtersByDate.forEach((item: any) => {
        if (item.filter === 'imprint') item.filter = 'Imprint';
        if (item.filter === 'publishing_model')
          item.filter = 'Publishing Model';
        if (item.filter === 'payment') item.filter = 'Payment';
        if (item.filter === 'licenses') item.filter = 'Licenses';
      });
      setFiltersByDate(filtersByDate);
    };
    getStatistics();
  }, [
    fetchStatisticsTop10,
    fetchStatisticsNoResults,
    fetchStatisticsByDate,
    fetchJournalsByDate,
    fetchFiltersByDate,
    fetchEventsByDate,
    fetchAccount,
    startDate,
    endDate,
    loggedInAccount,
  ]);

  /** If full account name is not found or something goes wrong, return the abbrevation */
  const getFullAccountName = (abbreviation: string) => {
    let fullName;
    if (!accountList) {
      return abbreviation;
    }
    Object.keys(accountList).map((key: string) => {
      if (accountList[key] === abbreviation) {
        fullName = key;
      }
      return false;
    });
    if (fullName && fullName !== '') {
      return fullName;
    }
    return abbreviation;
  };

  const inputDateHandler = (name: string, date: Dayjs | null) => {
    const dateString = dayjs(date).format('YYYY-MM-DD');
    if (name === 'start_date') setStartDate(dayjs(dateString));
    else setEndDate(dayjs(dateString));
  };

  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: true,
        color: 'white',
      },
      legend: {
        display: false,
        position: 'right' as const,
      },
      title: {
        display: true,
      },
    },
  };
  const optionsVertical = {
    indexAxis: 'y' as const,
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      datalabels: {
        display: true,
        color: 'white',
      },
      legend: {
        display: false,
        position: 'right' as const,
      },
      title: {
        display: true,
      },
    },
  };

  const labelsDates: string[] = [];
  let currentDate = startDate?.startOf('day');
  while (currentDate?.isBefore(endDate) || currentDate?.isSame(endDate)) {
    labelsDates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'day');
  }

  const dataSearches = {
    labels: labelsDates,
    datasets: [
      {
        label: 'Searches',
        data: labelsDates.map((label) => {
          const found = byDate.find(
            (item) => item.date === label && item.event === 'search'
          );
          return found?.count || 0;
        }),
        borderColor: Colors.scifreePrimary,
        backgroundColor: Colors.scifreePrimary,
      },
    ],
  };

  const labelClicks = ['Journal', 'Payments', 'Licenses', 'Publishing Models'];

  const dataClicks = {
    labels: labelClicks,
    datasets: [
      {
        label: 'Journal',
        data: [
          totalJournal,
          totalPayments,
          totalLicenses,
          totalPublishingModels,
        ],
        borderColor: Colors.scifreePrimary,
        backgroundColor: Colors.scifreePrimary,
      },
    ],
  };

  const labelFilters = ['Imprint', 'Payment', 'Licenses', 'Publishing Model'];

  const dataFilters = {
    labels: labelFilters,
    datasets: [
      {
        label: 'Imprint',
        data: [
          totalFilterImprint,
          totalFilterPayments,
          totalFilterLicenses,
          totalFilterPublishingModel,
        ],
        borderColor: Colors.scifreePrimary,
        backgroundColor: Colors.scifreePrimary,
      },
    ],
  };

  const dataTop10 = {
    labels: top10.map((statistic: any) => statistic.term),
    datasets: [
      {
        label: 'Top 10',
        data: top10.map((statistic: any) => statistic.count),
        borderColor: Colors.scifreePrimary,
        backgroundColor: Colors.scifreePrimary,
      },
    ],
  };

  const averagResults = {
    labels: top10.map((statistic: any) => statistic.term),
    datasets: [
      {
        label: 'Average Results',
        data: top10.map((statistic: any) => statistic.avg_results),
        borderColor: Colors.scifreePrimary,
        backgroundColor: Colors.scifreePrimary,
      },
    ],
  };

  const dataNoResults = {
    labels: noResults.map((statistic: any) => statistic.term),
    datasets: [
      {
        label: 'No Results',
        data: noResults.map((statistic: any) => statistic.count),
        borderColor: Colors.scifreePrimary,
        backgroundColor: Colors.scifreePrimary,
      },
    ],
  };

  const [value, setValue] = React.useState('1');

  const handleClick = () => {
    let csv = 'Search Term, Count\n';
    noResults.forEach((item: any) => {
      csv += `${item.term}, ${item.count}\n`;
    });
    const link = document.createElement('a');
    link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    link.target = '_blank';
    link.download = 'noResults.csv';
    link.click();
  };
  type JournalsByDate = {
    agreement_id: string;
    agreement: string;
    count: number;
    name: string;
    imprint: string;
    payment: string;
    publishing_model: string;
  };

  type Order = 'asc' | 'desc';
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof JournalsByDate
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedJournals = React.useMemo(() => {
    if (!journalsByDate) return [];

    return journalsByDate.sort((a, b) => {
      const valueA = a[orderBy];
      const valueB = b[orderBy];
      if (valueA! > valueB!) return order === 'asc' ? 1 : -1;
      if (valueA! < valueB!) return order === 'asc' ? -1 : 1;
      return 0;
    });
  }, [journalsByDate, order, orderBy]);

  const getArrowIcon = (key: keyof JournalsByDate) => {
    if (orderBy === key) {
      return order === 'asc' ? (
        <ExpandMore fontSize="small" style={arrowStyling} />
      ) : (
        <ExpandMore fontSize="small" style={arrowStylingDefault} />
      );
    }
    return <UnfoldMore fontSize="small" style={arrowStylingDefault} />;
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: 12,
              marginBottom: 12,
            }}
            component={Paper}
          >
            {' '}
            <Typography variant="h1" fontSize={20}>
              {t('statistics.title')}
            </Typography>
            <Typography variant="subtitle1">
              {t('statistics.totalGraph.selectDates')}
            </Typography>
            <Grid container columnSpacing={12}>
              <Grid item xs={2}>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      sx={{ marginTop: '20px' }}
                      label={t('statistics.totalGraph.startDate')}
                      slots={{
                        nextIconButton: (props) => (
                          <ArrowRightIcon
                            {...props}
                            sx={{
                              fontSize: '24px',
                              color: 'gray',
                              '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                borderRadius: '50%',
                              },
                            }}
                          />
                        ),

                        previousIconButton: (props) => (
                          <ArrowLeftIcon
                            {...props}
                            sx={{
                              fontSize: '24px',
                              color: 'gray',
                              '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                borderRadius: '50%',
                              },
                            }}
                          />
                        ),
                      }}
                      defaultValue={startDate}
                      format="YYYY-MM-DD"
                      maxDate={dayjs(endDate)}
                      views={['year', 'month', 'day']}
                      onChange={(date) => inputDateHandler('start_date', date)}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label={t('statistics.totalGraph.endDate')}
                      slots={{
                        nextIconButton: (props) => (
                          <ArrowRightIcon
                            {...props}
                            sx={{
                              fontSize: '24px',
                              color: 'gray',
                              '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                borderRadius: '50%',
                              },
                            }}
                          />
                        ),

                        previousIconButton: (props) => (
                          <ArrowLeftIcon
                            {...props}
                            sx={{
                              fontSize: '24px',
                              color: 'gray',
                              '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                borderRadius: '50%',
                              },
                            }}
                          />
                        ),
                      }}
                      defaultValue={endDate}
                      format="YYYY-MM-DD"
                      minDate={dayjs(startDate)}
                      onChange={(date) => inputDateHandler('end_date', date)}
                      sx={{ borderRadius: 0, marginTop: '20px' }}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
            </Grid>
          </Box>
          <Box component={Paper}>
            <Typography
              variant="h2"
              sx={{ fontSize: 16, p: '12px 12px 0px 12px' }}
            >
              {t('statistics.totalGraph.title')} {totalSearch}
            </Typography>
            <Line options={options} data={dataSearches} height={50} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TabContext value={value}>
            <Box
              component={Paper}
              sx={{
                marginTop: 12,
              }}
            >
              <StyledTabBtn
                onClick={() => setValue('1')}
                sx={value === '1' ? activeTabStyles : {}}
              >
                {t('statistics.tabs.toplist')}
              </StyledTabBtn>
              <StyledTabBtn
                onClick={() => setValue('2')}
                sx={value === '2' ? activeTabStyles : {}}
              >
                {t('statistics.tabs.average')}
              </StyledTabBtn>
              <StyledTabBtn
                onClick={() => setValue('3')}
                sx={value === '3' ? activeTabStyles : {}}
              >
                {t('statistics.tabs.noResults')}
              </StyledTabBtn>
              <StyledTabBtn
                onClick={() => setValue('4')}
                sx={value === '4' ? activeTabStyles : {}}
              >
                {t('statistics.tabs.filters')}
              </StyledTabBtn>
              <StyledTabBtn
                onClick={() => setValue('5')}
                sx={value === '5' ? activeTabStyles : {}}
              >
                Filters
              </StyledTabBtn>
              <StyledTabBtn
                onClick={() => setValue('6')}
                sx={value === '6' ? activeTabStyles : {}}
              >
                {t('statistics.tabs.journals')}
              </StyledTabBtn>
              <StyledTabBtn
                onClick={() => setValue('7')}
                sx={value === '7' ? activeTabStyles : {}}
              >
                Account activity
              </StyledTabBtn>
            </Box>
            <TabPanel value="1" sx={{ p: 0 }}>
              <Box component={Paper}>
                <Typography
                  variant="h2"
                  sx={{ fontSize: 16, p: '0px 12px 0px 12px', pt: '15px' }}
                >
                  {t('statistics.tabs.toplist')}
                </Typography>
                <div style={{ position: 'relative', height: '45vh' }}>
                  <Bar
                    options={optionsVertical}
                    plugins={[ChartDataLabels]}
                    data={dataTop10}
                  />
                </div>
              </Box>
            </TabPanel>
            <TabPanel value="2" sx={{ p: 0 }}>
              <Box
                sx={{
                  marginLeft: 1,
                }}
                component={Paper}
              >
                <Typography
                  variant="h2"
                  sx={{ fontSize: 16, p: '12px 12px 0px 12px' }}
                >
                  {t('statistics.tabs.average')}
                </Typography>
                <div style={{ position: 'relative', height: '45vh' }}>
                  <Bar
                    options={optionsVertical}
                    plugins={[ChartDataLabels]}
                    data={averagResults}
                  />
                </div>
              </Box>
            </TabPanel>
            <TabPanel value="3" sx={{ p: 0 }}>
              <Box component={Paper}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography variant="h2" sx={{ fontSize: 16, p: '12px' }}>
                    {t('statistics.tabs.noResults')}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                    sx={{ margin: 12 }}
                  >
                    {t('statistics.csv')}
                  </Button>
                </div>
                <div
                  style={{
                    position: 'relative',
                    height: '45vh',
                  }}
                >
                  <Bar
                    options={optionsVertical}
                    plugins={[ChartDataLabels]}
                    data={dataNoResults}
                  />
                </div>
              </Box>
            </TabPanel>
            <TabPanel value="4" sx={{ p: 0 }}>
              <Box component={Paper}>
                <Typography variant="h2" sx={{ fontSize: 16, p: '12px' }}>
                  {t('statistics.tabs.filters')}
                </Typography>
                <div
                  style={{
                    position: 'relative',
                    height: '50vh',
                  }}
                >
                  <Bar
                    options={options}
                    plugins={[ChartDataLabels]}
                    data={dataClicks}
                  />
                </div>
              </Box>
            </TabPanel>
            <TabPanel value="5" sx={{ p: 0 }}>
              <Box component={Paper}>
                <Typography variant="h2" sx={{ fontSize: 16, p: '12px' }}>
                  Filters
                </Typography>
                <div
                  style={{
                    position: 'relative',
                    height: '50vh',
                  }}
                >
                  <Bar
                    options={options}
                    plugins={[ChartDataLabels]}
                    data={dataFilters}
                  />
                </div>
              </Box>
            </TabPanel>
            <TabPanel value="6" sx={{ p: 0 }}>
              <Box component={Paper}>
                <Typography variant="h2" sx={{ fontSize: 16, p: '12px' }}>
                  {t('statistics.tabs.journals')}
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ alignItems: 'start' }}>
                          <StyledButton
                            onClick={(event) =>
                              handleRequestSort(event, 'count')
                            }
                          >
                            {t('statistics.table.count')}
                            {getArrowIcon('count')}
                          </StyledButton>
                        </TableCell>
                        <TableCell sx={{ alignItems: 'start' }}>
                          <StyledButton
                            onClick={(event) =>
                              handleRequestSort(event, 'agreement')
                            }
                          >
                            {t('statistics.table.agreement')}
                            {getArrowIcon('agreement')}
                          </StyledButton>
                        </TableCell>
                        <TableCell sx={{ alignItems: 'start' }}>
                          <StyledButton
                            onClick={(event) =>
                              handleRequestSort(event, 'name')
                            }
                          >
                            {t('statistics.table.name')}
                            {getArrowIcon('name')}
                          </StyledButton>
                        </TableCell>
                        <TableCell sx={{ alignItems: 'start' }}>
                          <StyledButton
                            onClick={(event) =>
                              handleRequestSort(event, 'imprint')
                            }
                          >
                            {t('statistics.table.imprint')}
                            {getArrowIcon('imprint')}
                          </StyledButton>
                        </TableCell>
                        <TableCell sx={{ alignItems: 'start' }}>
                          <StyledButton
                            onClick={(event) =>
                              handleRequestSort(event, 'payment')
                            }
                          >
                            {t('statistics.table.payment')}
                            {getArrowIcon('payment')}
                          </StyledButton>
                        </TableCell>
                        <TableCell sx={{ alignItems: 'start' }}>
                          <StyledButton
                            onClick={(event) =>
                              handleRequestSort(event, 'publishing_model')
                            }
                          >
                            {t('statistics.table.publishingModel')}
                            {getArrowIcon('publishing_model')}
                          </StyledButton>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedJournals.map((row: any) => (
                        <TableRow
                          key={row._id}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell>{row.count}</TableCell>
                          <TableCell>{row.agreement}</TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.imprint}</TableCell>
                          <TableCell>{row.payment}</TableCell>
                          <TableCell>{row.publishing_model}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </TabPanel>
            <TabPanel value="7" sx={{ p: 0 }}>
              <Box component={Paper}>
                <Typography variant="h2" sx={{ fontSize: 16, p: '12px' }}>
                  Account activity
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ alignItems: 'start' }}>
                          {t('statistics.table.noOfSearches')}
                        </TableCell>
                        <TableCell sx={{ alignItems: 'start' }}>
                          {t('statistics.table.account')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {eventsByDate.map((row: any) => (
                        <TableRow
                          key={row.account}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell>{row.count}</TableCell>
                          <TableCell>
                            {getFullAccountName(row.account)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </div>
  );
}

export default Statistics;
