import { SidebarItemsType } from '../../types/sidebar';
import Agreements from '@mui/icons-material/HistoryEdu';
import Search from '@mui/icons-material/ManageSearch';
import Statistics from '@mui/icons-material/AutoGraph';
import UserManagement from '@mui/icons-material/PeopleOutline';
import { BrowserUpdated, OpenInNew } from '@mui/icons-material';
import BrandingIcon from '@mui/icons-material/Brush';
import { useAuthContext } from '../../contexts/AuthContext';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import React from 'react';

const baseUrl = process.env.REACT_APP_API_URL || 'https://api.jstscifree.com';
const searchUrl = process.env.REACT_APP_PUBLIC_SEARCH_URL || "https://search.scifree.se"

const DashboardItems = () => {
  const { account } = useAuthContext();
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = React.useState<string>('');
  useEffect(() => {
    const fetchToken = async () => {
      const accessToken = await getAccessTokenSilently();
      setToken(accessToken);
    };
    fetchToken();
  });

  const pagesSection = [
    {
      children: [
        {
          href: '/agreements',
          title: 'Agreements',
          icon: Agreements,
        },
        {
          href: '/internal-search',
          title: 'Internal Search',
          icon: Search,
        },
        {
          href: '/statistics',
          title: 'Statistics',
          icon: Statistics,
        },
        {
          href: '/user-management',
          title: 'Admin Management',
          icon: UserManagement,
        },
        {
          href: '/branding',
          title: 'Branding',
          icon: BrandingIcon,
        },
        {
          href: `${searchUrl}/${account}`,
          title: 'Public Search',
          icon: OpenInNew,
        },
        {
          href: `${baseUrl}/export?token=${token}&context=${account}`,
          title: 'Export Journals',
          icon: BrowserUpdated,
        },
      ],
    },
  ] as SidebarItemsType[];

  return pagesSection;
};

export default DashboardItems;
