import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';

const baseUrl = process.env.REACT_APP_API_URL || 'https://api.jstscifree.com';

export const useStatistics = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { account } = useAuthContext();

  const makeApiRequest = useCallback(
    async (
      url: string,
      method: AxiosRequestConfig['method'],
      data: any = null
    ) => {
      const accessToken = await getAccessTokenSilently();

      const config: AxiosRequestConfig = {
        method,
        url: `${baseUrl}${url}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'X-SciFree-Context': account,
        },
        data,
      };

      const response: AxiosResponse = await axios(config);
      return response.data;
    },
    [getAccessTokenSilently, account]
  );

  const fetchStatisticsTop10 = useCallback(async () => {
    return (await makeApiRequest)('/statistics/top10', 'GET');
  }, [makeApiRequest]);

  const fetchStatisticsNoResults = useCallback(async () => {
    return (await makeApiRequest)('/statistics/no_results', 'GET');
  }, [makeApiRequest]);

  const fetchStatisticsByDate = useCallback(
    async (startDate: string, endDate: string) => {
      return (await makeApiRequest)(
        `/statistics/by_date?from=${startDate}&to=${endDate}`,
        'GET'
      );
    },
    [makeApiRequest]
  );

  const fetchJournalsByDate = useCallback(
    async (startDate: string, endDate: string) => {
      return (await makeApiRequest)(
        `/statistics/journals?from=${startDate}&to=${endDate}`,
        'GET'
      ).then((journals: any) => {
        return journals.sort((a: any, b: any) => {
          return b.count - a.count;
        });
      });
    },
    [makeApiRequest]
  );

  const fetchFiltersByDate = useCallback(
    async (startDate: string, endDate: string) => {
      return (await makeApiRequest)(
        `/statistics/filters?from=${startDate}&to=${endDate}`,
        'GET'
      ).then((filters: any) => {
        return filters.sort((a: any, b: any) => {
          return b.count - a.count;
        });
      });
    },
    [makeApiRequest]
  );

  const fetchEventsByDate = useCallback(
    async (startDate: string, endDate: string) => {
      return (await makeApiRequest)(
        `/statistics/events?from=${startDate}&to=${endDate}`,
        'GET'
      ).then((events: any) => {
        return events.sort((a: any, b: any) => {
          return b.count - a.count;
        });
      });
    },
    [makeApiRequest]
  );

  return {
    fetchStatisticsTop10,
    fetchStatisticsNoResults,
    fetchStatisticsByDate,
    fetchJournalsByDate,
    fetchFiltersByDate,
    fetchEventsByDate,
  };
};
