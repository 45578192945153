import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';

const baseUrl = process.env.REACT_APP_API_URL || 'https://api.jstscifree.com';

export const useBranding = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { account } = useAuthContext();

  const makeApiRequest = useCallback(
    async (
      url: string,
      method: AxiosRequestConfig['method'],
      data: any = null
    ) => {
      const accessToken = await getAccessTokenSilently();

      const config: AxiosRequestConfig = {
        method,
        url: `${baseUrl}${url}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'X-SciFree-Context': account,
        },
        data,
      };

      const response: AxiosResponse = await axios(config);
      return response.data;
    },
    [getAccessTokenSilently, account]
  );

  const fetchBranding = useCallback(async () => {
    return (await makeApiRequest)('/branding', 'GET');
  }, [makeApiRequest]);

  const updateBranding = async ({ data }: any) => {
    const accessToken = await getAccessTokenSilently();
    return axios.put(`${baseUrl}/branding`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'X-SciFree-Context': account,
      },
    });
  };

  const fetchCustomInfo = useCallback(async () => {
    if (account) {
      return (await makeApiRequest)(`/custom/${account}.json`, 'GET');
    }
  }, [makeApiRequest, account]);

  return { fetchBranding, updateBranding, fetchCustomInfo };
};

export const useDeleteBranding = () => {
  const { getAccessTokenSilently } = useAuth0();
  const deleteBranding = async (account: string) => {
    const accessToken = await getAccessTokenSilently();
    return axios.delete(`${baseUrl}/branding`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'X-SciFree-Context': account,
      },
    });
  };

  return { deleteBranding };
};
