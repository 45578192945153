import styled from '@emotion/styled';
import { Typography, Box, Divider } from '@mui/material';
import Quill from './Quill';

const BottomWrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

const StyledBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 30px;
`;

type TextTabProps = {
  searchTitle: string;
  setSearchTitle: React.Dispatch<React.SetStateAction<string>>;
  infoBoxText: string;
  setInfoBoxText: React.Dispatch<React.SetStateAction<string>>;
  startingInstructionText: string;
  setStartingInstructionText: React.Dispatch<React.SetStateAction<string>>;
  nothingFoundText: string;
  setNothingFoundText: React.Dispatch<React.SetStateAction<string>>;
};

const TextTab = ({
  searchTitle,
  setSearchTitle,
  infoBoxText,
  setInfoBoxText,
  startingInstructionText,
  setStartingInstructionText,
  nothingFoundText,
  setNothingFoundText,
}: TextTabProps) => {
  return (
    <BottomWrapper>
      <Box>
        <Typography variant="subtitle2" mb={10}>
          Text
        </Typography>
      </Box>
      <Divider />
      <StyledBox
        sx={{
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <Typography variant="subtitle2" mb={10} flex={0.1} textAlign="start">
          Edit text over the search bar
        </Typography>
        <div style={{ flex: '0.8' }}>
          <Quill
            value={searchTitle}
            onChange={setSearchTitle}
            placeholder="Search bar text"
          />
        </div>
      </StyledBox>
      <Divider />
      <StyledBox
        sx={{
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <Typography variant="subtitle2" mb={10} flex={0.1} textAlign="start">
          Edit text in the right information box
        </Typography>
        <div style={{ flex: '0.8' }}>
          <Quill
            value={infoBoxText}
            onChange={setInfoBoxText}
            placeholder="Information box text"
          />
        </div>
      </StyledBox>
      <Divider />
      <StyledBox
        sx={{
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <Typography variant="subtitle2" mb={10} flex={0.1} textAlign="start">
          Edit starting instructions text, at the middle of the page
        </Typography>
        <div style={{ flex: '0.8' }}>
          <Quill
            value={startingInstructionText}
            onChange={setStartingInstructionText}
            placeholder="Starting instruction text"
          />
        </div>
      </StyledBox>
      <Divider />
      <StyledBox
        sx={{
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <Typography variant="subtitle2" mb={10} flex={0.1} textAlign="start">
          Edit text when nothing is found, at the middle of the page
        </Typography>
        <div style={{ flex: '0.8' }}>
          <Quill
            value={nothingFoundText}
            onChange={setNothingFoundText}
            placeholder="Nothing found text"
          />
        </div>
      </StyledBox>
    </BottomWrapper>
  );
};

export default TextTab;
