import { useAuth0 } from '@auth0/auth0-react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useUsers } from '../hooks/api/useUsers';

interface User {
  [key: string]: any;
}

interface AuthContextType {
  user: User | undefined;
  account: string;
  setAccount: (account: string | null) => void;
  fetchAccountList: () => Promise<void>;
  accountList: Record<string, string>;
  loggedInAccount: string;
}

const AuthContext = createContext<AuthContextType>({
  user: undefined,
  account: '',
  setAccount: () => {},
  fetchAccountList: async () => {},
  accountList: {},
  loggedInAccount: '',
});

export function useAuthContext() {
  return useContext(AuthContext);
}

export const AuthContextProvider = ({ children }: any) => {
  const { user } = useAuth0();
  const { fetchAccount } = useUsers();

  const currentAccount =
    user?.[`https://api.jstscifree.com/app_metadata`]?.account;
  const [account, setAccount] = useState<string | null>(null);
  const [accountList, setAccountList] = useState<Record<string, string>>({});
  const [loggedInAccount, setLoggedInAccount] = useState('');

  const savedAccount = sessionStorage.getItem('account');
  useEffect(() => {
    setAccount(savedAccount || currentAccount);
    setLoggedInAccount(currentAccount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount]);

  useEffect(() => {
    if (account) {
      sessionStorage.setItem('account', account);
    }
  }, [account]);

  const fetchAccountList = useCallback(async () => {
    const res = await fetchAccount();
    setAccountList(res || {});
  }, [fetchAccount]);

  const contextValue: AuthContextType = {
    user,
    account: account || '',
    setAccount,
    fetchAccountList,
    accountList,
    loggedInAccount,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
