import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

import {
  Alert as MuiAlert,
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper as MuiPaper,
  TextField,
} from '@mui/material';
import { spacing } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import { useUsers } from '../../hooks/api/useUsers';
import { useAuthContext } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';

const Button = styled(MuiButton)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Alert = styled(MuiAlert)`
  position: fixed;
  padding: 20px;
  right: 0;
  top: 70px;
  z-index: 1;
`;

const StyledTextField = styled(TextField)`
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  &:focus {
    outline: 2px solid #177d8e;
  }
`;

export type SimpleDialogProps = {
  text: string;
};

const AddAccountDialog = ({ text }: SimpleDialogProps) => {
  const { fetchAccountList } = useAuthContext();
  const { createAccount, fetchAccount } = useUsers();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [path, setPath] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [pathErrorMessage, setPathErrorMessage] = useState('');
  const [accountList, setAccountList] = useState<Record<string, string>>({});
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchAccount().then((res) => setAccountList(res || {}));
  }, [fetchAccount]);

  const validatePath = (value: string) => {
    if (/[^a-zA-Z0-9\-_]/.test(value)) {
      return 'Path should not contain special characters';
    }
    if (/^[0-9]/.test(value)) {
      return 'Path should not start with a number';
    }
    if (/-/.test(value) && /^-/.test(value)) {
      return 'Path should not start with a dash';
    }
    if (/-/.test(value) && /-$/.test(value)) {
      return 'Path should not end with a dash';
    }
    if (/--/.test(value)) {
      return 'Path should not contain consecutive dashes';
    }
    if (/[A-Z]/.test(value)) {
      return 'Path should not contain uppercase letters';
    }
    if (/__/.test(value)) {
      return 'Path should not contain consecutive underscores';
    }

    return '';
  };

  const handleSubmit = () => {
    setNameErrorMessage('');
    setPathErrorMessage('');

    const newNameErrorMessage = !name ? 'Please enter account name' : '';
    const newPathErrorMessage = !path
      ? 'Please enter account path'
      : validatePath(path);

    setNameErrorMessage(newNameErrorMessage);
    setPathErrorMessage(newPathErrorMessage);

    const pathExists = Object.values(accountList).some(
      (account) => account === path
    );

    const nameExists = Object.keys(accountList).some(
      (account) => account === name
    );

    if (nameExists) {
      setNameErrorMessage('Name already exists');
      return;
    }

    if (pathExists) {
      setPathErrorMessage('Path already exists');
      return;
    }

    if (newNameErrorMessage || newPathErrorMessage) {
      return;
    }

    if (name && path) {
      createAccount({ data: { name, path } })
        .then(() => {
          setOpen(false);
          setName('');
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
          }, 1600);
          fetchAccountList();
        })
        .catch((error) => {
          const errors = error.response.data;
          const pathError = errors?.path?.length > 0;
          if (pathError) {
            setPathErrorMessage('Path already exists');
          }
          console.error('Error creating account:', error);
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 1600);
        });
    }
  };

  return (
    <>
      <StyledButton
        variant="text"
        color="secondary"
        onClick={() => setOpen(true)}
        sx={{ marginRight: '14px' }}
      >
        <AddIcon sx={{ fontSize: 16, marginRight: 4 }} />
        {text}
      </StyledButton>
      <Paper mt={4}>
        <div>
          <Dialog
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              {t('navbar.addAccountDialog.title')}
            </DialogTitle>
            <DialogContent>
              <Grid mb={6}>
                <DialogContentText>
                  {t('navbar.addAccountDialog.subtext')}
                </DialogContentText>
              </Grid>
              <StyledTextField
                required
                margin="dense"
                id="name"
                label={t('navbar.addAccountDialog.name')}
                type="text"
                fullWidth
                onChange={(e) => setName(e.target.value)}
                error={!!nameErrorMessage}
                helperText={nameErrorMessage}
              />
              <StyledTextField
                required
                margin="dense"
                id="path"
                label={t('navbar.addAccountDialog.path')}
                type="text"
                fullWidth
                onChange={(e) => setPath(e.target.value)}
                error={!!pathErrorMessage}
                helperText={pathErrorMessage}
              />
            </DialogContent>
            <DialogActions>
              <StyledButton
                onClick={() => {
                  setOpen(false);
                  setNameErrorMessage('');
                  setPathErrorMessage('');
                }}
                color="primary"
              >
                {t('navbar.addAccountDialog.cancel')}
              </StyledButton>
              <StyledButton onClick={handleSubmit} color="primary">
                {t('navbar.addAccountDialog.create')}
              </StyledButton>
            </DialogActions>
          </Dialog>
        </div>
        {showToast && (
          <Alert severity="success">
            {t('navbar.addAccountDialog.success')}
          </Alert>
        )}
        {error && (
          <Alert severity="error">{t('navbar.addAccountDialog.error')}</Alert>
        )}
      </Paper>
    </>
  );
};

export default AddAccountDialog;
