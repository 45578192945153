import { Box, Grid } from '@mui/material';
import styled from '@emotion/styled';

type IconUploadProps = {
  label: string;
  name: string;
  handleIconChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  src: string;
};

const LogoLabel = styled.label`
  margin-bottom: 12px;
`;

const IconUpload = ({
  label,
  name,
  handleIconChange,
  src,
}: IconUploadProps) => {
  return (
    <Grid item display="flex">
      <Box
        sx={{
          width: '60px',
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'center',
          border: '1px solid #C4C4C4',
        }}
      >
        {src && (
          <img
            alt="icon"
            src={src}
            style={{
              maxHeight: '60px',
              maxWidth: '60px',
              textAlign: 'center',
              margin: '5px',
            }}
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        // border="1px solid #C4C4C4"
        marginLeft="10px"
        padding="5px"
      >
        <LogoLabel htmlFor={name}>{label}</LogoLabel>
        <input
          type="file"
          id={name}
          name={name}
          accept=".png, .svg"
          onChange={handleIconChange}
        />
      </Box>
    </Grid>
  );
};

export default IconUpload;
